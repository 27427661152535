<template>

<div>
  <el-empty v-if="state.firstPage" class="card" description="Votre clé API MERAKI n'est pas saisie ou n'est pas valide">
    <router-link to="/profil" class="link-primary fs-6 fw-bolder btn btn-primary mt-7 mx-7">
      Accèder à votre profil utilisateur afin de saisir la clé API
    </router-link>
    <br><br><hr><br>
    <div style="font-size: var(--el-font-size-base); color: var(--el-text-color-secondary);">Vous ne possédez pas de clé MERAKI ?</div>
    <br>
    <a target='_BLANK' class="link-primary fs-6 fw-bolder btn btn-secondary mt-7 mx-7" href="https://documentation.meraki.com/General_Administration/Other_Topics/Cisco_Meraki_Dashboard_API">Accèder à la documentation MERAKI</a>
  </el-empty>

  <div class="card" v-if="!state.loaderEnabled && !state.firstPage">
        <div class="card-header border-0 pt-6 pb-6">
          <el-select
            v-model="state.currentOrga"
            placeholder="Organisation"
            class="mt-4 w-350px"
            @change="displayOrga()"
          >
            <el-option v-for="item, index in state.mOrga" :key="index" :label="item.name" :value="item.id" />
          </el-select>

            <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                :key="state.loadingFilter"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                widthDecalage="120"
                :searchBar = 0
                classSearch = "d-block"
                classGroupSelect="align-items-center"
                classSelect="mx-0 text-primary me-3 m-2 mt-2"
                ref="filtersSAF"
              ></SearchAndFilter>
        </div>
      </div>

      <div
        style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
        v-if="state.loaderEnabled"
      >
        <div class="lds-ring">
          <img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
          <br>
          Interrogation de l'API Meraki...
      </div>

      <transition name="fade">
   
        <el-container class="p-0" v-if="!state.firstPage">
          <el-aside width="360px" style="overflow: hidden;">
            <div class="card mt-6 me-4" v-if="!state.loaderEnabled && state.myList">
            <div class="card-body pt-0">

            <el-tabs class="tabsPresta pt-9 mb-5">
              <el-tab-pane disabled :label="$t('Sous organisation')" ></el-tab-pane>
            </el-tabs>            

            <template  v-if="state.ssOrga && state.ssOrga.length > 0">
              <div class="mt-0">{{ $t('Filtre Sous Organisation') }}:</div>

              <el-select
                v-model="state.currentSSOrga"
                placeholder="Organisation"
                @change="displayOrga(state.currentSSOrga);"
              >
                <el-option v-for="item, index in state.ssOrga" :key="index" :label="item.ssm_sous_orga" :value="item.ssm_seq" />
              </el-select>
            </template>

            <div v-if="state.role=='Administrateur'" class="btn btn-secondary mt-6" style="height: 41px" @click="gestSousOrgaMeraki()">
              Gérer les sous organisations 
            </div>

            <el-tabs class="tabsPresta pt-9 mb-5">
              <el-tab-pane disabled :label="$t('Synthèse et filtres')" ></el-tab-pane>
            </el-tabs>

            <div class="card-title">
              <h4 class="fw-bolder mb-0 mt-6">{{$t("Comparer les affectations de licences :")}}</h4>
            </div>

            <div class="mt-6">{{$t('Licences affectées depuis le')}}</div><input class="form form-control w-200px" type="date" v-model="state.depuisLe">

            <div class="mt-6">{{$t("Jusqu'au")}}</div><input class="form form-control w-200px" type="date" v-model="state.jusquAu">

            <div class="card-title mt-10">
              <h4 class="fw-bolder mb-0 mt-6">{{$t("Sous totaux :")}}</h4>
            </div>

            <div>
              <b>{{$t('Quantité totale au')}} {{ formatDate(state.jusquAu) }} {{$t('de')}}&nbsp;  </b><span class="fs-4 text-primary fw-bolder">{{ total() }}</span>
            </div>
            <div>
              <b>{{$t('Quantité totale au')}} {{ formatDate(state.depuisLe) }} {{$t('de')}}&nbsp;  </b><span class="fs-4 text-primary fw-bolder ">
                {{ calctotalAvantDepuisLe() }}
              </span>
            </div>

            <div>
              <b>Soit un ajout de </b><span class="fs-4 text-primary fw-bolder">{{ total() - calctotalAvantDepuisLe() }}</span> <b>licence(s)</b>
            </div>

            <div class="btn btn-primary mt-8 mb-6" @click="ExportLicences(false)">
              Exporter toutes les licences
            </div>

            <div class="btn btn-primary mt-2 mb-6" @click="ExportLicences(true)">
              Exporter les {{ total() - calctotalAvantDepuisLe() }} licences ajoutées
            </div>
            
            </div>
          </div>
          </el-aside>
          <el-main class="p-0">
            <div class="card mt-6" v-if="!state.loaderEnabled">
            <div class="card-body pt-0">

            <div
              v-if="state.myList.length == 0"
              class=" alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-7 mt-10 "
            >
              <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                <inline-svg src="media/icons/duotune/general/gen048.svg" />
              </span>
              <div class="mt-4 d-flex flex-column">
                <h4 class="mb-1 text-dark">{{$t("Aucune licence à afficher")}}</h4>
                <span
                  >{{$t("Il n'existe aucune licence à afficher correspondant à vos critères de recherche.")}}</span
                >
              </div>
            </div>
            <Datatable
              v-if="state.myList && state.myList.length > 0"
              :table-data="state.myList"
              :table-header="tableHeader"
              :enable-items-per-page-dropdown="true"
              empty-table-text="Aucun licence à afficher."
              :key="state.loadingDatatable"
              :rowsPerPage="100"
              class="mt-2"
            >

              <template v-slot:cell-model="{ row: data }">
                <div class="" >
                  <span class="fs-4 text-dark"><b>{{ data.model }}</b></span>
                </div>
              </template>

              <template v-slot:cell-mode="{ row: data }">
                <span class="fs-7 text-dark"><b>{{ data.mode.toUpperCase() }}</b></span><br>
                <br>
                <span class="fs-6 text-gray-600"><b>Du {{ formatDate(data.startedAt) }} au {{ formatDate(data.endDate) }}</b></span><br>
              </template>

              <template v-slot:cell-edition="{ row: data }">
                <div class="badge badge-light-primary" >
                  {{ data.edition }} / {{ data.productType }}
                </div>
                
              </template>

              <template v-slot:cell-licences="{ row: data }">
                <div class="w-100px text-dark fs-4 " style="text-align: left" >
                  <span v-if="!data.xEA" class="fs-5 ms-3 fw-bolder">{{ data.licences }}</span>
                  <div v-else class="badge badge-secondary " >Enterprise Agreement</div>
                </div>
              </template>

              <template v-slot:cell-total="{ row: data }">
                <div class="w-100px text-dark fs-4 " style="text-align: right" >
                  <span class="fs-4 ms-3 fw-bolder">{{ data.total }}</span><br>
                  <div v-if="data.devices.filter(el => el.new ).length > 0" class="badge badge-light-warning"><span class="fs-7 text-gray-700">
                    {{ data.devices.filter(el => el.new ).length }} en +
                  </span></div>
                </div>
              </template>

              <template v-slot:cell-devices="{ row: data }">
                <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark"  @click="affLicence(data)" >
                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                    <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                    {{$t("Inventaire")}}
                </a>
              </template>
            </Datatable>

            </div>
          </div>
          </el-main>

          

        </el-container>   


  </transition>
  <el-drawer v-model="state.drawer" :with-header="false" size="100%" modal-class="modal-transp" >
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Inventaire de la licence ")}} {{ state.licenceSelect.model }}
          </h1>
        </div>
      </div>
      <div class="card-body position-relative" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">

        <div class="card-header border-0 pt-6 pb-6 bg-white">
            <SearchAndFilter
              :listInitial="state.licenceSelectInit.devices"
              :filters="[]"
              :key="state.loadingFilterInvent"
              @returnSearch="state.licenceSelect.devices = $event"
              @returnLoadingDatatable="state.loadingDatatableInvent = $event"
            ></SearchAndFilter>
        </div>

        <div v-if="state.licenceSelect.devices.length == 0" class=" alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10 mt-5">
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="mt-4 d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucun materiel à afficher")}}</h4>
            <span>{{$t("Il n'existe aucun matériel rattaché a cette licence à afficher correspondant à vos critères de recherche.")}}</span>
          </div>
        </div>

        <Datatable
          v-if="state.licenceSelect.devices && state.licenceSelect.devices.length > 0"
          :table-data="state.licenceSelect.devices"
          :table-header="tableHeaderInvent"
          :enable-items-per-page-dropdown="true"
          empty-table-text="Aucun licence à afficher."
          :key="state.loadingDatatableInvent"
          :rowsPerPage="100"
          class="mt-2 bg-white p-10"
        >

          <template v-slot:cell-name="{ row: data }">
            <span class="fs-1"><b>{{ data.new ? '🆕' : '' }}</b></span>
            <span class="fs-5 text-dark"><b>{{ data.networkName }}</b></span>
            <span v-if="data.name" class="fs-5 text-gray-500"><b><br>{{ data.name }}</b></span>
          </template>

          <template v-slot:cell-ssOrga="{ row: data }">
            <br><span class="fs-5 text-dark"><b>{{ data.ssOrga }}</b></span>
          </template>

          <template v-slot:cell-serial="{ row: data }">
            <div class="" >
              <span class="fs-5 text-dark"><b>{{ data.serial }}</b></span><br>
              <span class="fs-5 text-gray-500"><b>{{ data.mac }}</b></span>
            </div>
          </template>

          <template v-slot:cell-productType="{ row: data }">
            <div class="badge badge-light-primary fs-7" >{{ data.productType.toUpperCase() }}</div>
            <div class="badge badge-light-info fs-7 mx-2" v-if="data.productType.toUpperCase()=='WIRELESS'">{{ data.model.toUpperCase() }}</div> 
          </template>
          <template v-slot:cell-claimedAt="{ row: data }">
            <span class="fs-5 text-gray-600"><b>{{ formatDate(data.claimedAt) }} </b></span>
          </template>
        </Datatable>

      </div>
    </div>
  </el-drawer>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import XLSX from "xlsx";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Liste des EA Meraki",
  components: {
    Datatable,
    SearchAndFilter,
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {

    const store = useStore();
    const router = useRouter();

    const formatDateOld = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois commence à 0, donc ajouter 1
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    
    const filtersSAF = ref(null) as any

    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      loadingFilter : 0,
      search: "",
      contratSelect: {},

      role : store.getters.currentUser.con_role_web,

      currentOrga : 0 as any,
      currentSSOrga : 0 as any,
      mOrga : [] as any,
      mLicences : [] as any,

      allDevices : [] as any,

      depuisLe : formatDateOld(new Date(new Date().getFullYear()-1, new Date().getMonth() , 1)), 
      jusquAu : formatDateOld(new Date()),

      licenceSelectInit : {} as any,
      licenceSelect : {} as any,
      loadingFilterInvent  : 0,
      loadingDatatableInvent : 0,

      firstPage : false,

      ssOrga : [] as any,

    });

    const tableHeader = ref([
      { name: "Licence", key: "model", sortable: true },
      { name: "Mode / Période", key: "mode", sortable: true },
      { name: "Edition", key: "edition", sortable: true },
      { name: "Licences", key: "licences", sortable: true },
      { name: "Consommation", key: "total", sortable: true },
      { name: "Actions", key: "devices", sortable: true },
    ]);

    const tableHeaderInvent = ref([
      { name: "Réseau / Libellé", key: "name", sortable: true },
      { name: "Sous Organisation", key: "ssOrga", sortable: true },
      { name: "N° de série / Adresse Mac", key: "serial", sortable: true },
      { name: "Type", key: "productType", sortable: true },
      { name: "Claim", key: "claimedAt", sortable: true },
    ]);


    const tableFilters = ref([
      { name: "Ajout de licence", key: "isAdd", label: "Ajout de licence", vOptions: [{label:'', value:'Oui'}] },
      { name: "Edition", key: "edition", label: "Edition" },
    ]);

    
   
    const ExportLicences = (justAdd) => {

      const sExcelFilename = "Licences_EA_Meraki";
      const devices = [] as any;

      state.initialMyList.forEach(element => {
        element.devices.forEach(dev => {
          if(justAdd && !dev.new) return;

          devices.push(
            {
              "newLicence" : dev.new,
              "SousOrganisation" : dev.ssOrga,
              "claimedAt": dev.claimedAt,
              "countryCode": dev.countryCode,  
              "mac": dev.mac,
              "model": dev.model,
              "name": dev.name,
              "networkId": dev.networkId,
              "networkName": dev.networkName,
              "orderNumber": dev.orderNumber,
              "productType": dev.productType,
              "serial": dev.serial,
            }
          );
        });
      });

      const data = XLSX.utils.json_to_sheet(devices);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      autoFitColumns(data, devices);
      XLSX.writeFile(wb, `${sExcelFilename}.xlsx`);

    }

    const autoFitColumns = (sheet, json) => {
      const objectMaxLength = [] as any;
      json.forEach((row) => {
        Object.keys(row).forEach((key, colIndex) => {
          const valueLength = row[key] ? row[key].toString().length : 10;
          objectMaxLength[colIndex] = objectMaxLength[colIndex] >= valueLength
            ? objectMaxLength[colIndex]
            : valueLength;
        });
      });
      const wscols = objectMaxLength.map((width) => {
        return { width: width + 4 };
      });
      sheet["!cols"] = wscols;
    };

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const total = () => {
      let total = 0 as any;
      state.initialMyList.forEach(element => {
        total = parseInt(total) + parseInt(element.total)
      });
      return total;
    }


    const calcDevices = () => {
      state.initialMyList.forEach(element => {
        element.devices.forEach(dev => {
          const dateTest = moment.default(dev.claimedAt, 'YYYY-MM-DD');
          const dateDebut = moment.default(state.depuisLe, 'YYYY-MM-DD');
          const dateFin = moment.default(state.jusquAu, 'YYYY-MM-DD');
          dev.new = dateTest.isBetween(dateDebut, dateFin, undefined, '[]');
        });
      });
      filtersSAF.value?.newInitialList(state.initialMyList);
    }
    
    watch(() => state.depuisLe, calcDevices);
    watch(() => state.jusquAu, calcDevices);


    const calctotalAvantDepuisLe = () => {
      let totalAjout = 0;
      state.initialMyList.forEach(element => {
        element.devices.forEach(dev => {
          if (!dev.new) totalAjout++;
        });
      });
      return totalAjout;
    }
    
    const affLicence = (data) => {
      state.licenceSelectInit = data;
      state.licenceSelect = data;
      state.loadingDatatableInvent = state.loadingDatatableInvent +1;
      state.drawer = true;

    };
    
    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Gestion Entreprises Agreement MERAKI", []);

      let myOrga = await mAxiosApi.prototype.getAxios("/testAPIMerakiUser");
      if(myOrga.results == 'KO' || myOrga.results.length == 0) {
        state.firstPage = true;
        state.loaderEnabled = false;
        return false;
      }

      //let myOrga = await mAxiosApi.prototype.getAxios("/getEAMerakiOrga");
      state.mOrga = myOrga.results;

      const mCode = router.currentRoute.value.params.code as string;
      if (mCode) {
        state.currentOrga = mCode;
      }else {
        state.currentOrga = state.mOrga[0].id;
      }
      

      displayOrga();

    });


    const gestSousOrgaMeraki = () => {
      router.push({ name: "ssOrgaMeraki", params: { code: state.currentOrga } });
    }

    const displayOrga = async (ssOrga = 0) => {
      state.loaderEnabled=true;
      let mLicences = [] as any;
      if(ssOrga) {
        mLicences = await mAxiosApi.prototype.getAxios("/getEAMerakiLicences/"+state.currentOrga + "/" + ssOrga);
      } else {
        mLicences = await mAxiosApi.prototype.getAxios("/getEAMerakiLicences/"+state.currentOrga);
      }

      if (store.getters.currentUser.con_x_restr_ssorga == 1 || ssOrga){
        mLicences.results = mLicences.results.filter(el => el.total > 0);
      }
      
      state.mLicences = mLicences.results;

      state.ssOrga =  mLicences.ssOrga;
      
      if (state.ssOrga && state.ssOrga.length > 1) {
        state.ssOrga.unshift({
            "ssm_seq": 0,
            "ssm_sous_orga": "Toutes",
        },)
      }

      if (state.ssOrga && state.ssOrga.length > 0 && state.currentSSOrga==0) {
        state.currentSSOrga = state.ssOrga[0].ssm_seq;
      }

      state.mLicences.forEach(element => {
          element['isAdd'] = 'Non';
      });

        state.mLicences.push({
          "licences": 0,
          "model": "toDel",
          "total": 0,
          "mode": "",
          "startedAt": "",
          "claimedAt": "",
          "edition": "",
          "productType": "",
          "devices": [],
          "xEA": true,
          "isAdd": "Oui"
        },)

      state.initialMyList = state.mLicences;
      state.myList = state.mLicences;

      calcDevices();

      const allDevices = state.myList.reduce((acc, item) => {return acc.concat(item.devices);}, []);
      state.allDevices = allDevices;
           
      state.loaderEnabled = false;

      state.initialMyList.pop();
      filtersSAF.value?.newInitialList(state.initialMyList);
    }

    return {
      tableHeader,
      state,
      formatDate,
      tableFilters,
      displayOrga,
      total,
      filtersSAF,
      affLicence,
      tableHeaderInvent,
      ExportLicences,
      gestSousOrgaMeraki,
      calcDevices,
      calctotalAvantDepuisLe
    };
  },
});
</script>

<style>
.el-dropdown-menu_item:hover {
  cursor: pointer;
  background-color: #e4e7ed !important;
}
</style>