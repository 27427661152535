
import { defineComponent, ref, onMounted, reactive, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import XLSX from "xlsx";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Liste des EA Meraki",
  components: {
    Datatable,
    SearchAndFilter,
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {

    const store = useStore();
    const router = useRouter();

    const formatDateOld = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois commence à 0, donc ajouter 1
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    
    const filtersSAF = ref(null) as any

    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      loadingFilter : 0,
      search: "",
      contratSelect: {},

      role : store.getters.currentUser.con_role_web,

      currentOrga : 0 as any,
      currentSSOrga : 0 as any,
      mOrga : [] as any,
      mLicences : [] as any,

      allDevices : [] as any,

      depuisLe : formatDateOld(new Date(new Date().getFullYear()-1, new Date().getMonth() , 1)), 
      jusquAu : formatDateOld(new Date()),

      licenceSelectInit : {} as any,
      licenceSelect : {} as any,
      loadingFilterInvent  : 0,
      loadingDatatableInvent : 0,

      firstPage : false,

      ssOrga : [] as any,

    });

    const tableHeader = ref([
      { name: "Licence", key: "model", sortable: true },
      { name: "Mode / Période", key: "mode", sortable: true },
      { name: "Edition", key: "edition", sortable: true },
      { name: "Licences", key: "licences", sortable: true },
      { name: "Consommation", key: "total", sortable: true },
      { name: "Actions", key: "devices", sortable: true },
    ]);

    const tableHeaderInvent = ref([
      { name: "Réseau / Libellé", key: "name", sortable: true },
      { name: "Sous Organisation", key: "ssOrga", sortable: true },
      { name: "N° de série / Adresse Mac", key: "serial", sortable: true },
      { name: "Type", key: "productType", sortable: true },
      { name: "Claim", key: "claimedAt", sortable: true },
    ]);


    const tableFilters = ref([
      { name: "Ajout de licence", key: "isAdd", label: "Ajout de licence", vOptions: [{label:'', value:'Oui'}] },
      { name: "Edition", key: "edition", label: "Edition" },
    ]);

    
   
    const ExportLicences = (justAdd) => {

      const sExcelFilename = "Licences_EA_Meraki";
      const devices = [] as any;

      state.initialMyList.forEach(element => {
        element.devices.forEach(dev => {
          if(justAdd && !dev.new) return;

          devices.push(
            {
              "newLicence" : dev.new,
              "SousOrganisation" : dev.ssOrga,
              "claimedAt": dev.claimedAt,
              "countryCode": dev.countryCode,  
              "mac": dev.mac,
              "model": dev.model,
              "name": dev.name,
              "networkId": dev.networkId,
              "networkName": dev.networkName,
              "orderNumber": dev.orderNumber,
              "productType": dev.productType,
              "serial": dev.serial,
            }
          );
        });
      });

      const data = XLSX.utils.json_to_sheet(devices);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      autoFitColumns(data, devices);
      XLSX.writeFile(wb, `${sExcelFilename}.xlsx`);

    }

    const autoFitColumns = (sheet, json) => {
      const objectMaxLength = [] as any;
      json.forEach((row) => {
        Object.keys(row).forEach((key, colIndex) => {
          const valueLength = row[key] ? row[key].toString().length : 10;
          objectMaxLength[colIndex] = objectMaxLength[colIndex] >= valueLength
            ? objectMaxLength[colIndex]
            : valueLength;
        });
      });
      const wscols = objectMaxLength.map((width) => {
        return { width: width + 4 };
      });
      sheet["!cols"] = wscols;
    };

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const total = () => {
      let total = 0 as any;
      state.initialMyList.forEach(element => {
        total = parseInt(total) + parseInt(element.total)
      });
      return total;
    }


    const calcDevices = () => {
      state.initialMyList.forEach(element => {
        element.devices.forEach(dev => {
          const dateTest = moment.default(dev.claimedAt, 'YYYY-MM-DD');
          const dateDebut = moment.default(state.depuisLe, 'YYYY-MM-DD');
          const dateFin = moment.default(state.jusquAu, 'YYYY-MM-DD');
          dev.new = dateTest.isBetween(dateDebut, dateFin, undefined, '[]');
        });
      });
      filtersSAF.value?.newInitialList(state.initialMyList);
    }
    
    watch(() => state.depuisLe, calcDevices);
    watch(() => state.jusquAu, calcDevices);


    const calctotalAvantDepuisLe = () => {
      let totalAjout = 0;
      state.initialMyList.forEach(element => {
        element.devices.forEach(dev => {
          if (!dev.new) totalAjout++;
        });
      });
      return totalAjout;
    }
    
    const affLicence = (data) => {
      state.licenceSelectInit = data;
      state.licenceSelect = data;
      state.loadingDatatableInvent = state.loadingDatatableInvent +1;
      state.drawer = true;

    };
    
    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Gestion Entreprises Agreement MERAKI", []);

      let myOrga = await mAxiosApi.prototype.getAxios("/testAPIMerakiUser");
      if(myOrga.results == 'KO' || myOrga.results.length == 0) {
        state.firstPage = true;
        state.loaderEnabled = false;
        return false;
      }

      //let myOrga = await mAxiosApi.prototype.getAxios("/getEAMerakiOrga");
      state.mOrga = myOrga.results;

      const mCode = router.currentRoute.value.params.code as string;
      if (mCode) {
        state.currentOrga = mCode;
      }else {
        state.currentOrga = state.mOrga[0].id;
      }
      

      displayOrga();

    });


    const gestSousOrgaMeraki = () => {
      router.push({ name: "ssOrgaMeraki", params: { code: state.currentOrga } });
    }

    const displayOrga = async (ssOrga = 0) => {
      state.loaderEnabled=true;
      let mLicences = [] as any;
      if(ssOrga) {
        mLicences = await mAxiosApi.prototype.getAxios("/getEAMerakiLicences/"+state.currentOrga + "/" + ssOrga);
      } else {
        mLicences = await mAxiosApi.prototype.getAxios("/getEAMerakiLicences/"+state.currentOrga);
      }

      if (store.getters.currentUser.con_x_restr_ssorga == 1 || ssOrga){
        mLicences.results = mLicences.results.filter(el => el.total > 0);
      }
      
      state.mLicences = mLicences.results;

      state.ssOrga =  mLicences.ssOrga;
      
      if (state.ssOrga && state.ssOrga.length > 1) {
        state.ssOrga.unshift({
            "ssm_seq": 0,
            "ssm_sous_orga": "Toutes",
        },)
      }

      if (state.ssOrga && state.ssOrga.length > 0 && state.currentSSOrga==0) {
        state.currentSSOrga = state.ssOrga[0].ssm_seq;
      }

      state.mLicences.forEach(element => {
          element['isAdd'] = 'Non';
      });

        state.mLicences.push({
          "licences": 0,
          "model": "toDel",
          "total": 0,
          "mode": "",
          "startedAt": "",
          "claimedAt": "",
          "edition": "",
          "productType": "",
          "devices": [],
          "xEA": true,
          "isAdd": "Oui"
        },)

      state.initialMyList = state.mLicences;
      state.myList = state.mLicences;

      calcDevices();

      const allDevices = state.myList.reduce((acc, item) => {return acc.concat(item.devices);}, []);
      state.allDevices = allDevices;
           
      state.loaderEnabled = false;

      state.initialMyList.pop();
      filtersSAF.value?.newInitialList(state.initialMyList);
    }

    return {
      tableHeader,
      state,
      formatDate,
      tableFilters,
      displayOrga,
      total,
      filtersSAF,
      affLicence,
      tableHeaderInvent,
      ExportLicences,
      gestSousOrgaMeraki,
      calcDevices,
      calctotalAvantDepuisLe
    };
  },
});
